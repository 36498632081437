<template>
  <div>
    <form
      @submit.prevent="submitHandler"
      @keyup.enter="handleKeyPress"
      v-if="isLoading == false"
      style="padding-bottom: 200px"
    >
      <div class="formgrid p-fluid">
        <div class="p-fluid formgrid grid justify-content-around">
          <div class="field col-6">
            <h2>
              <template>{{ uid ? "Edit" : "Add" }} Cost Type</template>
            </h2>
          </div>
          <div class="field col-6">
            <div class="p-inputgroup flex justify-content-end">
              <AInputSwitch
                v-model="cost_type.active"
                class="green"
              />
            </div>
          </div>
          <Divider class="m-2 mb-5"></Divider>
          <div class="field col-12">
            <label for="name">Cost type name</label>
            <div class="p-inputgroup">
              <InputText
                placeholder="Cost type name"
                v-model="cost_type.type_name"
              />
            </div>
            <div
              class="error-validation-text pt-1"
              v-if="submitted && !$v.cost_type.type_name.required"
            >
              Cost type name is required
            </div>
          </div>
          <div class="field col-12 sm:col-6">
            <div class="flex align-items-center justify-content-between">
              <label for="name">Supplier</label>
              <Button
                label="Add Supplier"
                class="p-button-text p-button-primary w-auto p-2"
                icon="las la-plus"
                @click="navigateToAddSupplier()"
              />
            </div>
            <div class="p-inputgroup">
              <Dropdown
                :options="accountSuppliers"
                dataKey="uid"
                v-model="cost_type.supplier"
                optionLabel="company_name"
                @input="setCurrency"
                placeholder="Select Supplier"
                class="input"
              />
            </div>
            <div
              class="error-validation-text pt-1"
              v-if="submitted && !$v.cost_type.supplier.required"
            >
              Supplier is required
            </div>
          </div>
          <!-- <div class="field col-12 sm:col-6">
            <label for="name"> Supplier</label>
            <div class="p-inputgroup">
              <Dropdown
                :options="accountSuppliers"
                dataKey="uid"
                v-model="cost_type.supplier"
                optionLabel="company_name"
                @input="setCurrency"
                placeholder="Select Supplier"
                class="input"
              />
            </div>
            <div
              class="error-validation-text pt-1"
              v-if="submitted && !$v.cost_type.supplier.required"
            >
              Supplier is required
            </div>
          </div> -->
          <div
            class="field col-12 sm:col-6"
            style="padding-top: 0.5rem"
          >
            <label class="label">Cost Currency</label>
            <div class="p-inputgroup">
              <Dropdown
                :options="currencies"
                placeholder="Cost Currency"
                optionLabel="name"
                optionValue="uid"
                v-model="cost_type.currency_uid"
              >
              </Dropdown>
            </div>
            <div
              class="error-validation-text pt-1"
              v-if="submitted && !$v.cost_type.currency_uid.required"
            >
              Currency is required
            </div>
          </div>
          <div class="field col-12 sm:col-6">
            <label for="name">Pricing structure</label>
            <div class="p-inputgroup">
              <Dropdown
                :options="taskTypePricing.filter((a) => a.category === 'task')"
                v-model="cost_type.pricing_structure"
                optionLabel="name"
                dataKey="uid"
                placeholder="Select a Pricing Structure"
                class="input"
              />
            </div>
            <div
              class="error-validation-text pt-1"
              v-if="submitted && !$v.cost_type.pricing_structure.required"
            >
              Pricing Structure is required
            </div>
          </div>
          <div class="field col-6 sm:col-3">
            <label for="name">Cost price</label>
            <div class="p-inputgroup ">
              <span class="p-inputgroup-addon">{{ setCurrencySymbol }}</span>
              <InputNumber
                placeholder="Cost price"
                :minFractionDigits="2"
                :maxFractionDigits="2"
                v-model="cost_type.price"
                locale="en-US"
              />
            </div>
            <div
              class="error-validation-text pt-1"
              v-if="submitted && !$v.cost_type.price.required"
            >
              Cost price is required
            </div>
          </div>
          <div class="field col-6 sm:col-3">
            <div class="sm:mt-4 p-inputgroup flex align-items-center justify-content-center sm:justify-content-start">
              <AInputSwitch
                v-model="cost_type.billable"
                class="green"
              />
              <label class="label ml-3">Billable</label>
            </div>
          </div>
          <div class="field col-12 sm:col-6">
            <label for="name">Markup method</label>

            <div class="p-inputgroup">
              <Dropdown
                :options="taskTypePricing.filter((a) => a.category === 'cost')"
                v-model="cost_type.markup_method"
                optionLabel="name"
                dataKey="uid"
                placeholder="Select a Markup Method"
                class="input"
              />
            </div>
            <div
              class="error-validation-text pt-1"
              v-if="submitted && !$v.cost_type.markup_method.required"
            >
              Markup method is required
            </div>
          </div>

          <div class="field col-6 sm:col-3">
            <label for="name">Markup</label>
            <div class="p-inputgroup">
              <span class="p-inputgroup-addon">{{ setCurrencySymbol }}</span>
              <InputNumber
                :suffix="setSuffix"
                :minFractionDigits="2"
                :maxFractionDigits="2"
                mode="decimal"
                locale="en-US"
                placeholder="Markup"
                v-model="cost_type.default_markup"
              />
            </div>
            <div
              class="error-validation-text pt-1"
              v-if="submitted && !$v.cost_type.default_markup.required"
            >
              Markup is required
            </div>
          </div>
          <div class="field col-6 sm:col-3">
            <label for="name">Resell amount</label>
            <div class="p-inputgroup flex align-items-center pl-1">
              <div
                class="text-blue-300 font-medium"
                v-if="resellAmount"
              >
                {{ formatCurrency(resellAmount, setCurrencySymbol) }}
              </div>
              <div
                v-else
                class="font-light font-medium"
              >
                {{ setCurrencySymbol }} 0.00
              </div>
            </div>
          </div>

          <div class="field col-12 mt-3">
            <label for="name">Default description</label>
            <div>
              <Textarea
                ref="editor"
                v-model="cost_type.description"
                :autoResize="true"
              ></Textarea>
            </div>
            <div
              class="error-validation-text pt-1"
              v-if="submitted && !$v.cost_type.description.required"
            >
              Default description is required
            </div>
          </div>
          <div class="field col-12">
            <label for="name">Assign to specific department</label>
            <div class="p-inputgroup">
              <Dropdown
                :options="departments"
                display="chip"
                optionValue="uid"
                v-model="cost_type.department_uid"
                optionLabel="department_name"
                placeholder="Select Departments"
              />
            </div>
            <div
              class="error-validation-text pt-1"
              v-if="submitted && !$v.cost_type.department_uid.required"
            >
              Select at least one department
            </div>
          </div>

          <!-- <div class="field col-12 sm:col-6">
            <label for="name">GL Sales Code</label>
            <div class="p-inputgroup">
              <InputText v-model="cost_type.gl_sales_code" />
            </div>
          </div>
          <div class="field col-12 sm:col-6">
            <label for="name">GL Cost of Sales Code</label>
            <div class="p-inputgroup">
              <InputText v-model="cost_type.gl_cost_of_sales_code" />
            </div>
          </div> -->
        </div>
      </div>

      <div class="flex justify-content-between pt-5">
        <Button type="submit"> SAVE </Button>
        <Button
          class="p-button-text p-button-secondary"
          @click="closeSlider"
        >
          CANCEL
        </Button>
      </div>
    </form>

    <RocketLoad
      :full="true"
      v-else
    />
  </div>
</template>
<script>
import Vue from "vue";
import {
  state,
  // fetchTaskTypePricing,
  fetchDepartments,
  fetchSuppliers,
  fetchAccountCostTypes,
  fetchCurrency,
  fetchAccounts,
  fetchCostType,
} from "../../../services/data_service.js";
import { required } from "vuelidate/lib/validators";
export default {
  name: "UserSliderDetails",
  components: {},
  data() {
    return {
      submitted: false,
      formOptions: [],
      myFiles: [],
      name: "",
      checked: true,
      email: "",
      department: "",
      profilePicture: "",
      isLoading: false,
      loadingText: "Loading...",
      inputIsVisible: false,
      cost_type: {
        active: "1",
        billable: "1",
        is_template: "1",
        departments: [],
        pricing_structure: {},
        supplier: null,
        currency_uid: "",
        gl_sales_code: "",
        gl_cost_of_sales_code: "",
        markup_method: null,
      },
    };
  },
  validations: {
    cost_type: {
      type_name: {
        required,
      },
      price: {
        required,
      },
      markup_method: {
        required,
      },
      default_markup: {
        required,
      },
      description: {
        required,
      },
      supplier: {
        required,
      },
      currency_uid: {
        required,
      },
      pricing_structure: {
        required,
      },
      department_uid: {
        required,
      },
    },
  },
  async mounted() {
    this.isLoading = true;
    await this.loadData();
    if (this.uid) {
      this.cost_type = state.costType;
    }

    if (this.cost_type && !this.$route.fullPath.includes("/finance/estimate")) {
      if (this.cost_type.currency) {
        this.cost_type.currency_uid = this.cost_type.currency.uid;
      }
    } else {
      this.cost_type.currency_uid = this.accountFinance.currency?.uid;
    }

    this.cost_type.pricing_structure = this.taskTypePricing.find(
      (a) => a.uid === this.cost_type.pricing_structure
    );

    this.cost_type.markup_method = this.taskTypePricing.find(
      (a) => a.uid === this.cost_type.markup_method
    );

    this.cost_type.departments = this.departments.find(
      (a) => a.uid === this.cost_type?.department?.uid
    );

    this.isLoading = false;
  },
  methods: {
    handleKeyPress(event) {
      const editor = this.$refs.editor;
      if (editor && editor.$el.contains(event.target)) {
        // If "Enter" is pressed inside the editor, create a new line
        event.stopPropagation();
      } else {
        // Else, submit the form
        this.submitHandler();
      }
    },
    async loadData() {
      var arr = [
        // fetchTaskTypePricing(),
        fetchDepartments(),
        fetchSuppliers(),
        fetchCurrency(),
        fetchAccounts(),
      ];

      if (this.uid) {
        arr.push(fetchCostType(this.uid));
      }

      await Promise.all(arr);
    },
    closeSlider() {
      if (this.$route.fullPath.includes("/finance/estimate")) {
        return this.$store.dispatch("setEstimateCostTypeSliderView", "");
      }
      return this.clearSliderContent();
    },
    navigateToAddSupplier() {
      this.$router.push("/supplier");
      this.clearSliderContent();
    },
    submitHandler() {
      this.submitted = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.$toast.add({
          severity: "error",
          summary: "Please complete all the required fields",
          life: 3000,
        });
      } else {
        this.isLoading = true;
        this.cost_type.default_cost_price = this.cost_type.price;
        this.cost_type.resell_amount = this.resellAmount;
        this.cost_type.departments = [this.cost_type.departments];

        this.$axios
          .post(
            process.env.VUE_APP_ROOT_API +
              "/v1/costtype/" +
              (!this.uid || this.$route.fullPath.includes("/finance/estimate")
                ? "create"
                : this.uid),
            JSON.stringify(this.cost_type),
            {
              headers: { "Content-type": "application/json" },
            }
          )
          .then((response) => {
            this.isLoading = false;
            fetchAccountCostTypes(1);
            if (this.$route.fullPath.includes("/finance/estimate")) {
              return this.$store.dispatch("setEstimateCostTypeSliderView", "");
            }
            return this.clearSliderContent();
          })
          .catch((error) => {
            this.isLoading = false;
            if (error.response && error.response.status === 401) {
              console.error("NOOOOOOOOO!");
            } else {
              // Handle error however you want
            }
            console.error(error);
          });
      }
    },

    setCurrency() {
      this.cost_type.currency_uid = this.cost_type.supplier.currency_uid;
    },
  },
  computed: {
    setCurrencySymbol() {
      let symbol = null;
      this.currencies?.forEach((currency) => {
        if (currency.uid === this.cost_type.currency_uid) {
          symbol = currency.description;
        }
      });
      return symbol;
    },
    setSuffix() {
      let suffix = null;
      if (this.cost_type.markup_method) {
        if (this.cost_type.markup_method.name === "Percentage") {
          suffix = "%";
        } else {
          suffix = null;
        }
      }
      return suffix;
    },
    account_details() {
      return state.accounts;
    },
    accountFinance() {
      return this.account_details.finance;
    },
    selectedObject() {
      return this.$store.getters.selectedObject;
    },
    user() {
      return this.$store.getters.user;
    },
    uid() {
      return this.selectedObject?.uid;
    },

    currencies() {
      return state.currencies;
    },
    user_uid() {
      return this.$store.getters.user.uid;
    },

    taskTypePricing() {
      return state.taskTypePricing;
    },
    departments() {
      return state.departments;
    },
    accountSuppliers() {
      return state.suppliers;
    },
    resellAmount() {
      if (
        this.cost_type.pricing_structure &&
        this.cost_type.markup_method &&
        this.cost_type.price &&
        this.cost_type.default_markup
      ) {
        switch (this.cost_type.pricing_structure.name) {
          case "Rate per hour":
          case "Fixed price":
            switch (this.cost_type.markup_method.name) {
              case "Fixed price":
                return (
                  parseFloat(this.cost_type.price) +
                  parseFloat(this.cost_type.default_markup)
                );
              case "Percentage":
                var markup = 1 + this.cost_type.default_markup / 100;
                return (Number(markup) * Number(this.cost_type.price)).toFixed(
                  2
                );
            }
        }
      }
      return null;
    },
  },
  watch: {},
};
</script>